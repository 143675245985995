import React from 'react';
import { Layout, Seo } from 'components/common';
import { Intro } from 'components/landing';

const Home = () => (
	<Layout>
		<Seo />
		<Intro />
	</Layout>
);

export default Home;
